<template>
    <div class="caption">redirecting...</div>
</template>

<script>
export default {
    name: 'Redirect',
    mounted () {
        if(this.$route.query.loc) {
            let re = new RegExp("^(http|https)://", "i");
            let loc = this.$route.query.loc
            let match = re.test(loc)

            if(match)
                window.location.href = loc
            else
                window.location.href = `https://${loc}`
        }
    }
}
</script>

<style>

</style>